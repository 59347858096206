<template lang="pug">
  .c-navbar(
    style="z-index:100000000000"
  )
    router-link(to="/")
      img(src="@/assets/images/Logo.svg")
    ul
      li
        router-link( :to="{ name: 'StadisticsPage' }" )
          i.icon-estadisticas_ico
          span Dashboard
      li
        router-link( :to="{ name: 'PatientsPage' }" )
          i.icon-sesiones_ico
          span Pacientes
      li
        router-link( :to="{ name: 'ProfilePage' }")
          .nav-icon
            img.icon(
              src="@/assets/images/profile_icon.png"
            )
            span Perfil
      //- li
      //-   router-link( :to="{ name: 'ReportPage' }" )
      //-     i.icon-adjunto_ico
      //-     span Informe
      li
        a(
          @click="logoutAuth"
        )
          i.icon-cerrar_sesion_ico
          span Cerrar sesión
</template>
<script>
import { mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions({
      A_LOGOUT: 'Auth/A_LOGOUT'
    }),
    logoutAuth () {
      this.A_LOGOUT()
      this.$router.push({ name: 'LoginForm' })
    }
  }
}
</script>
<style lang="scss" scoped>
  .nav-icon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .icon {
      margin: 0 7px 0 0;
      width: 18px;
      height: 18px;
    }
  }

</style>
